.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
