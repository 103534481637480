.container {
  position: absolute;
  top: 0;
  right: 0;
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: flex-end;
  padding: 20px;
}

.container img {
  width: 24px;
  margin: 10px;
  border-radius: 25px;
  image-fit: contain;
}
