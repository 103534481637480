.modalDialog {
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  overflow: auto;
  width: 200px;
  height: 150px;
  border: none;
  border-radius: 0;
  display: flex;
  background-color: white;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.modalBody {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalBackdrop {
  background-color: #242526;
  opacity: 0.55 !important;
}

.audioGif {
  max-width: 70%;
  max-height: 70%;
  object-fit: contain;
}
