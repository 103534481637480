.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.container {
  height: 100%;
  width: 100%;
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: soft-light;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dataContainer {
  background-color: rgba(255, 255, 255, 0.9);
  width: 50%;
  padding: 50px;
}

.element {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.imageContainer {
  position: relative;
}

.statusContainer {
  position: absolute;
  top: calc(50% - 17px);
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.statusContainer p {
  margin-bottom: 0;
  text-align: center;
  color: #fff;
  padding: 10px 0;
  font-weight: bold;
  font-size: 14px;
}

.button {
  width: 40%;
}

.areaName {
  margin-top: 12px;
}

@media (max-width: 850px) {
  .dataContainer {
    background-color: rgba(255, 255, 255, 0.8);
    width: 85%;
    padding: 30px;
  }
  .statusContainer p {
    padding: 10px 0;
    font-size: 12px;
  }
  .button {
    width: 55%;
  }
  .areaName {
    font-size: 18px;
  }
}

@media (max-width: 450px) {
  .dataContainer {
    width: 95%;
    padding: 20px;
  }
  .statusContainer p {
    padding: 10px 0;
    font-size: 10px;
  }
  .button {
    width: 100%;
  }
  .areaName {
    font-size: 16px;
  }
}

@media screen and (max-width: 900px) and (orientation: landscape) {
  .dataContainer {
    margin-top: var(--headerHeight);
    width: 65%;
    padding: 5px;
  }
  .areaName {
    font-size: 16px;
  }
  .imageContainer {
    background-color: #fff;
    display: flex;
    justify-content: center;
  }
  .imageContainer img {
    max-height: 150px;
    padding: 15px;
  }

  .button {
    width: 45%;
  }
}
