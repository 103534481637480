.modalDialog {
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    border: none;
    padding: 20px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContent {
    max-width: 100%;
    overflow: auto;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
    display: flex;
    border-radius: 10px;
    background-color: #fff;
  }
  
  .modalContentSmall {
    width: 50%;
    height: 50%;
    background-color: rgba(255, 255, 255, 0.8);
  }
  
  .modalBackdrop {
    background-color: #242526;
    opacity: 0.65 !important;
  }
  
  .topContainer {
    display: flex;
    justify-content: flex-end;
    padding: 0 25px;
  }
  
  .topContainer p {
    cursor: pointer;
    margin-bottom: 0;
    font-size: 24px;
  }
  
  .contentContainer {
    display: flex;
    height: calc(100% - 55px);
  }
  
  .contentContainerFullHeight {
    height: 100%;
  }
  
  .exitContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    text-align: center;
  }
  .exitButtonsContainer {
    margin: 25px 0;
    display: flex;
    width: 100%;
  }
  .exitButtonContainer {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .exitButtonContainer button {
    margin: 0 15px;
    width: 100%;
  }
  
  @media (max-width: 1367px) {
    .modalContentSmall {
      width: 60%;
      height: 60%;
    }
  }
  
  @media (max-width: 767px) {
    .modalDialog {
      padding: 5px;
    }
    .modalContentSmall {
      width: 100%;
      height: 60%;
    }
  }
  
  @media screen and (max-width: 900px) and (orientation: landscape) {
    .modalDialog {
      padding: 5px;
    }
    .exitButtonContainer {
      margin-bottom: 0;
    }
    .exitButtonsContainer {
      margin-bottom: 0;
    }
  }
  