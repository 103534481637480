.mainAreaContainer {
  display: flex;
  height: 100%;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  opacity: 0.8;
}
