.modalDialog {
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  border: none;
  padding: 20px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  max-width: 100%;
  overflow: auto;
  width: 50%;
  height: 50%;
  border: none;
  border-radius: 0;
  display: flex;
  border-radius: 10px;
  background-color: #fff;
}

.modalBackdrop {
  background-color: #242526;
  opacity: 0.65 !important;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.mainText {
  font-size: 24px;
}

.subText {
  margin-top: 30px;
  font-size: 18px;
}

@media (max-width: 1367px) {
  .modalContent {
    width: 60%;
    height: 60%;
  }
}

@media (max-width: 767px) {
  .modalDialog {
    padding: 15px;
  }
  .modalContent {
    width: 100%;
    height: 60%;
  }
}

@media screen and (max-width: 900px) and (orientation: landscape) {
  .modalDialog {
    padding: 15px;
  }
  .modalContent {
    width: 100%;
    height: 80%;
  }
}
