.flexCol {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.loginButton {
  width: 100%;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.6);
  color: #000;
  border: 0;
  font-size: 21px;
  margin: 15px 0;
}

.inputField {
  font-size: 20px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 8px;
}

.logo {
  max-height: 75px;
}

.loginBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://content.helloviewer.io/1920/62624b8a0450323bda90e88e/20a350d6-b514-4f9a-9b04-12dde27ce9e5/interior/nz.jpg");
  background-position: center;
  background-size: cover;
  z-index: 0;
  filter: brightness(0.6);
}

.formContainer {
  max-height: 100%;
  position: relative;
  border-radius: 25px;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.7);
  width: 55%;
  margin: auto;
  overflow: auto;
  padding: 42px;
}

.title {
  margin: 82px 0;
  font-size: calc(1.325rem + 0.9vw);
  text-align: center;
}

.loginTitle {
  margin: 41px 0;
  text-align: center;
}

.underlined {
  border-bottom: 2px solid black;
}

.cursorPointer {
  cursor: pointer;
}

.loginOptionsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginOption {
  font-size: 24px;
  margin: 0 15px 15px 15px;
}

@media screen and (min-width: 1200px) {
  .formContainer {
    width: 45%;
  }
}


@media screen and (max-width: 420px) {
  .title {
    margin: 41px 0;
    font-size: calc(1rem + 0.9vw);
  }
  .formContainer {
    padding: 42px 12px;
    width: 92%;
  }
  .loginButton {
    font-size: 18px;
  }
  .logo {
    max-height: 50px;
  }
  .loginOption {
    font-size: calc(0.95rem + 0.9vw);
  }
}

@media screen and (max-width: 900px) and (orientation: landscape) {
  .title {
    margin: 41px 0;
    font-size: calc(1rem + 0.9vw);
  }
  .loginTitle {
    margin: 21px 0;
    font-size: calc(0.8rem + 0.9vw);
  }
  .formContainer {
    padding: 21px;
    max-width: 75%;
  }
  .loginButton {
    font-size: 18px;
  }
  .logo {
    max-height: 50px;
  }
  .loginOption {
    font-size: calc(0.95rem + 0.9vw);
  }
}
