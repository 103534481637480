:root {
  --primaryColor: rgb(20, 181, 36);
  --primary: rgb(20, 181, 36);
  --primaryDark: rgb(20, 181, 36);
  --primaryLight: #91dcaf;
  --backgroundDark: #141516;
  --backgroundSecondaryDark: #202020;
  --backgroundLight: #ffffff;
  --backgroundSecondaryLight: rgb(226, 226, 229);
  --fontColor: #000000;
  --headerHeight: 70px;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
