.modalDialog {
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  border: none;
  padding: 0;
  margin: 0;
}

.modalContent {
  max-width: 100%;
  overflow: auto;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 0;
  display: flex;
  padding: 0 300px;
}

.modalBackdrop {
  background-color: #242526;
  opacity: 0.95 !important;
}

.blockContainer {
  padding: 0 20px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.imageContainer {
  width: 100%;
  padding-bottom: 100%;
  background-color: #ededed;
  position: relative;
}

.imageContainer img {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.textContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: 20px;
  max-width: calc(100% - 40px);
}

.textContainer p {
  padding: 10px;
  margin: 0;
  font-weight: bold;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4);
}

.blockContainer:hover .textContainer p {
  color: #000;
  background-color: #fff;
}

.statusContainer {
  position: absolute;
  top: 40%;
  left: 20px;
  width: calc(100% - 40px);
  margin-right: 20px;
  background-color: rgba(0, 0, 0, 0.6);
}

.statusContainer img {
  width: 20px;
  height: 20px;
}

.statusContainer p {
  margin-bottom: 0;
  text-align: center;
  color: #fff;
  padding: 10px 0;
  font-weight: bold;
}

.logo {
  max-height: 105px;
}

@media (max-width: 1400px) {
  .modalContent {
    padding: 0 200px;
  }
}

@media (max-width: 850px) {
  .modalContent {
    padding: 0 50px;
  }
  .modalDialog {
    padding: 0;
  }
}

@media (max-width: 420px) {
  .modalContent {
    padding: 0 10px;
  }
  .textContainer p {
    padding: 10px;
  }
}

@media screen and (max-width: 900px) and (orientation: landscape) {
  .modalContent {
    padding: 0 50px;
  }
  .textContainer p {
    padding: 6px;
    font-size: 12px;
  }
  .statusContainer p {
    padding: 6px 0;
    font-size: 14px;
  }
}
