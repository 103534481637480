.videoContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.videoContainer video {
  position: absolute;
  width: 100%;
  max-width: calc(100% - 2rem);
  max-height: calc(100% - 2rem);
  object-fit: contain;
}
