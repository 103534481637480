.container {
  position: fixed;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 100;
  transition: all 1s ease-in-out;
}

.topBar {
  position: absolute;
  width: 100%;
  height: 70px;
  background: #fff;
  z-index: 101;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}

.closeButton {
  background: transparent;
  outline: none;
  border: none;
}

.sideBar {
  position: absolute;
  width: 70px;
  height: 100%;
  background: #fff;
}

.sideBarLeft {
  left: 0;
}

.sideBarRight {
  right: 0;
}
.buttonsContainerParent {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 5% 0 3% 5%;
  min-height: max-content;
}

.buttonsContainer {
  display: flex;
  width: auto;
  flex-direction: column;
}

.featureButton {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  outline: none;
  border: 2px solid #0274d8a9;
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;
  min-height: 3rem;
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.featureButtonChecked {
  background: rgba(255, 255, 255, 0.85);
}
.muteButton {
  background: rgba(255, 255, 255, 0.7);
  border: none;
  outline: none;

  right: 5%;
  bottom: 5%;
  margin: 0.75rem 0;
  border-radius: 50%;
}

.threeDeeViewerModal {
  position: fixed;
  z-index: 100011;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.567);
  display: flex;
  justify-content: center;
  align-items: center;
}

.threeDeeButton {
  position: absolute;
  z-index: 1000;
  width: max-content;
  right: 5%;
  bottom: 3%;
}

.viewerContainer {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
}

.featureButton:hover,
.muteButton:hover {
  background-color: #fff;
}

.audioButton {
  width: 20px;
  height: 20px;
  object-fit: contain;
  cursor: pointer;
  margin-right: 20px;
}

.featureButtonText {
  display: initial;
  overflow: hidden;
  white-space: nowrap;
  /* text-overflow: ellipsis; */
}

.audioTextContainerInactive {
  position: absolute;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 5px;
  border: none;
  right: 5%;
  bottom: 5%;
  transition: all 1s ease-in-out;
  transform: translateX(150%);
  margin: 0.5rem 0;
}
.audioTextContainerActive {
  transform: translateX(0);
}

@media screen and (max-width: 720px) {
  .topBar {
    min-height: 100px;
    padding: 0 1rem;
  }
}

@media screen and (max-width: 500px) {
  .featureButtonText {
    display: none;
  }
  .featureButton {
    justify-content: center;
  }
  .audioTextContainerInactive {
    display: none;
  }
}
