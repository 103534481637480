.container {
  width: 100%;
  height: 100%;
  margin: auto;
}

.loadingContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
