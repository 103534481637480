.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  background-color: rgba(255, 255, 255, 0.6);
}

.logo {
  max-height: 32px;
  max-width: 32px;
  object-fit: contain;
  margin-right: 10px;
}
