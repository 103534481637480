.container {
  background-image: url("https://content.helloviewer.io/ashirvad_lms_test/28_04_2023/images/ashirvad_bg.jpg"),
    url("https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/A_black_image.jpg/800px-A_black_image.jpg?20201103073518");
  background-color: #f1f1f1;
  background-position: center;
  background-repeat: repeat;
  background-size: contain;
  background-blend-mode: soft-light;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mainAreaContainer {
  display: flex;
  height: calc(100% - var(--headerHeight));
}

.summaryContainer {
  background-color: rgba(255, 255, 255, 0.8);
  width: 30%;
  max-height: 100%;
  overflow: auto;
  padding: 40px 20px;
}

.summaryColumn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.areaContainer {
  border-bottom: 1px solid #d3d3d3;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-bottom: 10px;
}

.summaryColumnTable {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  margin-top: 12px;
}

.summaryColumnHotspot {
  display: flex;
  justify-content: space-between;
}

.image {
  width: 20px;
  height: 20px;
}

.link {
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
  margin: 10px 0;
}

.congrats {
  font-size: 1.5em;
  font-weight: bold;
}

@media (max-width: 450px) {
  .summaryContainer {
    width: 95%;
  }
}

@media (max-width: 850px) and (min-width: 450px) {
  .summaryContainer {
    width: 60%;
  }
}

@media screen and (max-width: 900px) and (orientation: landscape) {
  .summaryContainer {
    width: 60%;
  }
}
