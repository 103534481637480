.container {
  height: var(--headerHeight);
  display: flex;
  align-items: center;
  color: black;
  background-color: #fff;
  margin: 0;
  padding: 0;
}

.audioButton {
  width: 20px;
  height: 20px;
  object-fit: contain;
  cursor: pointer;
  margin-right: 20px;
}

.profileButton {
  width: 20px;
  height: 20px;
  object-fit: contain;
  cursor: pointer;
}

.leftColContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  max-height: 100%;
  min-width: 0;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
}

.headingContainer {
  min-width: 0%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.areaName {
  font-weight: bold;
  margin: 0;
  font-size: 21px;
  padding: 5px;
  margin-right: 3px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.points {
  padding: 0;
  margin: 0;
  font-weight: bold;
  margin-left: 20px;
}

@media (max-width: 850px) {
  .headingContainer {
    min-width: 50%;
  }
}
