.inputField {
    font-size: 1rem !important;
    height: 48px !important;
    font-weight: bold;
    width: 100% !important;
    background-color: rgba(255, 255, 255, 0.6) !important;
    border-radius: 10px !important;
    padding: 3px 0px !important;
    padding-left: calc(0.75rem + 40px) !important;
  }

.inputField:focus {
    /* background-color: #fff !important; */
    color: #000 !important;
  }
  
  .inputField::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #828b91;
    opacity: 1; /* Firefox */
  }
  
  .react-tel-input {
    font-size: inherit !important;
  }
  .flag-dropdown {
    border-radius: 10px 0 0 10px !important;
    padding: 0 !important;
    overflow: hidden;
  }
  
  .selected-flag {
    padding-left: 12px !important;
  }
  
  @media (min-width: 720px) {
    .inputField {
      font-size: 1.5rem !important;
      height: 48px !important;
    }
    .label {
      font-size: 20px;
    }
  }
  
  @media (min-width: 1024px) {
    .inputField {
      font-size: 1.5rem !important;
      height: 48px !important;
      padding-left: calc(0.75rem + 60px) !important;
    }
    .selected-flag {
      padding-left: 20px !important;
    }
    .flag-dropdown {
      width: 60px;
    }
  }
  
  @media (min-width: 1280px) {
    .inputField {
      font-size: 1.5rem !important;
      height: 48px !important;
      padding-left: calc(0.75rem + 60px) !important;
      background: #000;
    }
    .selected-flag {
      padding-left: 20px !important;
    }
    .flag-dropdown {
      width: 60px;
    }
  }

  .inputStyle{
      width: 50px !important;
      height: 50px !important;
      border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.6) !important;
    border: none;
  }